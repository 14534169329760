<!--
 * @Author: 智客云网络科技
 * @Date: 2021-03-29 19:15:59 +0800
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-12 19:04:36
 * @Description: 登录页
 * @FilePath: \src\views\Frame\login.vue
-->

<template>
  <div class="view-box">
    <div class="bg-1"></div>
    <div class="bg-2"></div>
    <div class="login-box">
      <div class="login-box-2">
        <div class="login-top">
          <img src="@/assets/logo.png" class="logo-img" />
          <br />
          <span class="admin-title">飞鱼国际物流-TMS后台</span>

          <span class="admin-title"></span>
        </div>
        <div class="from-box">
          <h3 class="from-title">账号登录</h3>
          <el-form size="small" label-position="left" label-width="0px">
            <el-form-item>
              <el-input
                prefix-icon="el-icon-user"
                v-model="m.account"
                placeholder="请输入用户名"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-input
                prefix-icon="el-icon-unlock"
                v-model="m.password"
                type="password"
                placeholder="请输入密码"
                @keyup.native.enter="_Login()"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                style="width: 100%"
                @click="_Login()"
                >登录</el-button
              >
            </el-form-item>
            <el-form-item>
              <!-- <span style="color: #999;">提示信息</span> -->
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <!-- 底部 版权 -->
    <div
      style="
        position: absolute;
        bottom: 40px;
        width: 100%;
        text-align: center;
        color: #666;
      "
    >
      Copyright ©2021 智客云网络科技有限公司
    </div>
  </div>
</template>

<script>
import { login } from "@/api/api"; //公共接口
export default {
  data() {
    return {
      m: {
        account: "",
        password: "",
      },
    };
  },
  methods: {
    //登录
    _Login() {
      // 表单验证
      if (this.m.account == "") {
        return this.$utils.error("请输入用户名", 6);
      }

      if (this.m.password == "") {
        return this.$utils.error("请输入密码", 6);
      }

      // 开始登录
      const params = {
        account: this.m.account, //用户名
        password: this.m.password, //密码
      };
      login(params)
        .then((res) => {
          //写入Vuex
          this.$store.commit("SET_USERNAME", res.data.username); //用户名
          this.$store.commit("SET_NICKNAME", res.data.nickname); //用户昵称
          this.$store.commit("SET_SIGN", res.data.sign); //个签
          this.$store.commit("SET_TOKEN", res.data.token); //token
          this.$store.commit("SET_AVATAR", res.data.avatar); //头像
          this.$store.commit("SET_IS_LOGIN", true); //写入登录态
          this.$utils.success("登录成功，欢迎你：" + res.data.nickname);

          let userinfo = {
            username: res.data.username, //用户名
            nickname: res.data.nickname, //昵称
            avatar: res.data.avatar, //头像
            sign: res.data.sign, //个签
            isLogin: true, //是否登录
          };

          localStorage.setItem("userinfo", JSON.stringify(userinfo));
          localStorage.setItem("token", res.data.token);

          setTimeout(
            function () {
              this.$router.push({ name: "/" }); //跳转到首页
              this.$router.go(0);
            }.bind(this),
            800
          );
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },
  },
  mounted() {
    document.querySelector("title").innerHTML = "飞鱼国际物流-TMS 登录";
  },
};
</script>

<style scoped>
/* 视图盒子 */
.view-box {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000;
}
/* EAEFF3 */
.bg-1 {
  height: 50%;
  background: linear-gradient(to bottom right, #0466c5, #3496f5);
}
.bg-2 {
  height: 50%;
  background-color: #eaeff3;
}

.login-box {
  width: 400px;
  height: 400px;
  position: absolute;
  left: calc(50% - 200px);
  top: calc(50% - 250px);
}
/* .login-box{} */

/* logo */
.login-top {
  margin-bottom: 40px;
  text-align: center;
}
.logo-img {
  width: 100px;
  height: 100px;
  vertical-align: middle;
  border-radius: 50%;
  /* margin-left: -10px; */
  /* margin-right: 20px; */
}
.logo-img {
  position: relative;
  top: -5px;
}
.admin-title {
  font-size: 28px;
  color: #fff;
  font-weight: 700;
}

/* 表单 */
.from-box {
  padding: 20px 50px;
  background-color: #fff;
}
.from-box {
  border-radius: 1px;
  box-shadow: 1px 1px 2px #666;
}
.from-title {
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: center;
}
</style>
